import styled, { createGlobalStyle } from "styled-components";

/**
 *    +================+
 *    | ~globalStyles~ |
 *    +================+
 * 
 *    Yleisessä käytössä olevat tyylittelyt. Näitä käytetään muissa komponenteissa 
 *    ja niitä voidaan muokata lapsi-komponenteissa. 
 * 
 */

const GlobalStyles = createGlobalStyle`
    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        font-family: 'Roboto', sans-serif;
    }
    
    a {
        text-decoration: none;
        color: #d90;
    }
`;

export const PageFrame = styled.div`
    width: 100%;
    height: 380vh;
    position: absolute;
    top: 100vh;
    z-index: -5;
`;

export const PageContainer = styled.div`
    margin: 0 auto;
    padding: 0 50px;
    background: ${({ bc }) => (bc ? bc : 'transparent')};
    display: flex;

    flex-direction: ${({ direction }) => (direction ? direction : 'column')};
    justify-content: ${({ justify }) => (justify ? justify : 'center')};
    align-items: ${({ align }) => (align ? align : '')};
    width: ${({ width }) => (width ? width : '100%')};
    height: ${({ height }) => (height ? height : '')};
    margin: ${({ margin }) => (margin ? margin : '')};
    padding: ${({ padding }) => (padding ? padding : '')};

    @media screen and (max-width: 960px) {
        padding: 0 30px;
    }
`;

export const Section = styled.section`

    max-width: ${({ max_width }) => (max_width ? max_width : '1600px')};
    display: flex;
    z-index: ${({ zindex }) => (zindex ? zindex : '0')};
    background: ${({ background }) => (background ? background : '')};
    flex-direction: ${({ direction }) => (direction ? direction : 'column')};
    opacity: ${({ opacity }) => (opacity ? opacity : '1')};
    justify-content: ${({ justify }) => (justify ? justify : 'center')};
    align-items: ${({ align_items }) => (align_items ? align_items : '')};
    width: ${({ width }) => (width ? width : '')};
    height: ${({ height }) => (height ? height : '')};
    margin: ${({ margin }) => (margin ? margin : '')};
    margin-bottom: ${({ mb }) => (mb ? mb : '0')};
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    width: ${({ width }) => (width ? width : '100%')};
    align-items: ${({ align }) => (align ? align : 'center')};
    justify-content: ${({ justify }) => (justify ? justify : 'center')};
    height: ${({ height }) => (height ? height : '')};
    margin: ${({ margin }) => (margin ? margin : '')};
    margin-top: ${({ mt }) => (mt ? mt : '0')};
    margin-bottom: ${({ mb }) => (mb ? mb : '0')};
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    width: ${({ width }) => (width ? width : '100%')};
    align-items: ${({ align }) => (align ? align : 'center')};
    justify-content: ${({ justify }) => (justify ? justify : 'inherit')};
    height: ${({ height }) => (height ? height : '')};
    margin: ${({ margin }) => (margin ? margin : '')};
    margin-top: ${({ mt }) => (mt ? mt : '0')};
    margin-bottom: ${({ mb }) => (mb ? mb : '0')};
`;

export const TextWrapper = styled.span`
    font-size: ${({ font_size }) => (font_size ? font_size : '1.1rem')}; 
    color: ${({ color }) => (color ? color : 'white')};
    line-height: ${({ lh }) => (lh ? lh : '2.2rem')};
`;

export const Heading = styled.h1`
    font-size: ${({ font_size }) => (font_size ? font_size : 'clamp(2rem, 5vh, 6rem)')};
    font-weight: 700;
    color: white;
    text-shadow: 1px 1px black;
    text-align: center;
    margin-top: ${({ mt }) => (mt ? mt : '0')};
    margin-bottom: ${({ mb }) => (mb ? mb : '0')};
`;

export const SubHeading = styled.h2`
    font-size: clamp(1.4rem, 2vw, 2.8rem);
    color: ${({ color }) => (color ? color : 'white')};
    margin-top: ${({ mt }) => (mt ? mt : '0')};
    margin-bottom: ${({ mb }) => (mb ? mb : '0')};
`;

export const HThree = styled.h3`
    padding: ${({ padding }) => (padding ? padding : 'auto')};
    padding-top: ${({ pt }) => (pt ? pt : 'auto')};
    padding-bottom: ${({ pb }) => (pb ? pb : 'auto')};
    font-size: clamp(1.2rem, 1.5vw, 2.4rem);
    color: ${({ color }) => (color ? color : 'white')};
    font-weight: 600;
    align-self: ${({ align_self }) => (align_self ? align_self : 'inherit')};
    margin-top: ${({ mt }) => (mt ? mt : '0')};
    margin-bottom: ${({ mb }) => (mb ? mb : '0')};
    text-align: ${({ textAlign }) => (textAlign ? textAlign : 'auto')};
    justify-content: ${({ justify }) => (justify ? justify : '')};
    line-height: ${({ lh }) => (lh ? lh : '2.2rem')};
`;

export const Button = styled.button`
    padding: ${({ padding }) => (padding ? padding : '10px 20px')};
    border: 1px #001 solid;
    border-radius: 10px;
    margin-top: ${({ mt }) => (mt ? mt : '')};
    margin-bottom: ${({ mb }) => (mb ? mb : '')};
    background-color: #001;
    text-transform: uppercase;
    color: white;
    font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 'bold')};
    cursor: pointer;
    font-size: ${({ fontSize }) => (fontSize ? fontSize : '1.1rem')};
    width: ${({ width }) => (width ? width : 'inherit')};
    box-shadow: 0 0 10px 0 white;
    justify-self: ${({ justify }) => (justify ? justify : '')};
    z-index: 10;

    &:hover {
        transition: all 0.1s ease;
        color: #d90;
        box-shadow: 0 0 10px 0 #d90;
    }    
    
`;

export const Image = styled.img`
    object-fit: cover;
    width: ${({ width }) => (width ? width : '100%')};
    padding: ${({ padding }) => (padding ? padding : '0')};
    margin: ${({ margin }) => (margin ? margin : '0')};
    border: ${({ border }) => (border ? border : '0')};
    border-radius: ${({ border_radius }) => (border_radius ? border_radius : '0')};
    max-width: 100%;
    height: ${({ height }) => (height ? height : 'auto')};
`;

export const OrderedList = styled.ol`
    background-color: ${({ bc }) => (bc ? bc : 'white')};
    width: ${({ width }) => (width ? width : 'auto')};
    list-style-type: number;
    margin: ${({ margin }) => (margin ? margin : '0')};
    padding: ${({ padding }) => (padding ? padding : '0')};
`;

export const UnorderedList = styled.ul`
    background-color: ${({ bc }) => (bc ? bc : 'white')};
    width: ${({ width }) => (width ? width : 'auto')};
    
    margin: ${({ margin }) => (margin ? margin : '0')};
    padding: ${({ padding }) => (padding ? padding : '0')};

    list-style-type: square;
`;

export const ListItem = styled.li`
    overflow: visible;
    display: list-item;
    font-size: 0.8rem;
    width: ${({ width }) => (width ? width : 'auto')};
    margin: ${({ margin }) => (margin ? '50px' : '0')};
    padding: ${({ padding }) => (padding ? padding : '0 0 0 10px')};
    list-style: ${({ list_style }) => (list_style ? list_style : '')};
`;

export default GlobalStyles;
