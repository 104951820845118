import React, { useEffect, useRef } from 'react'
import { ContentsSectionData as csd } from '../../data/ContentsData'
import { Section, Row } from '../../globalStyles'
import {
  ContentContainer,
  ContentRow,
  ContentColumn,
  ContentTextWrapper,
  ContentSubHeading,
  ContentBoxContainer,
  ContentButton,
  ContentImage,
  ContentItem,
  ContentList,
  ContentListItem,
} from './ContentStyles'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { useAnimation, useInView } from "framer-motion"
import { Link, useLocation } from 'react-router-dom'
import Form from '../Form/Form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { addHtml } from '../Functions'


/**********************************************************|
 ***************|===========================|**************|
 ***************| ~~~Content-komponentti~~~ |**************|
 ***************|===========================|**************|
 *
 * Tämä on etusivun Contents-sisältötiedosto. 
 * Tiedoston avulla määritetään sivuston sisällön sijainti 
 * ja asettelu sivulla. 
 * 
 * Komponenatti käyttää framer-motion- kirjastoa palstojen
 * animoimiseen. 
 * 
 * ref, animation, transition, initial ja inView-
 * muuttujia käytetään palstojen animoinnissa. 
 * 
 * listingFunction nimensä mukaisesti järjestää data-
 * tiedostoissa olevan lista-taulukon tietueet 
 * html-listaksi verkkosivulle. 
 */

const Contents = () => {

  const ref = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);

  const transition = { delay: 0.9, duration: 0.5 }
  const initial = { opacity: 0, y: 30 };
  const animation = useAnimation();
  const animation2 = useAnimation();
  const animation3 = useAnimation();
  const animation4 = useAnimation();


  const inView = useInView(ref, { once: true, amount: 0.3 });
  const inView2 = useInView(ref2, { once: true, amount: 0.3 });
  const inView3 = useInView(ref3, { once: true, amount: 0.2 });
  const inView4 = useInView(ref4, { once: true, amount: 0.3 });

  const fadeIn = { y: 0, opacity: 1 }

  const animationArray = [animation, animation2, animation3, animation4]
  const refArray = [ref, ref2, ref3, ref4];

  const location = useLocation();
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [location]);

  useEffect(() => {
    if (inView) {
      animation.start({ y: fadeIn.y, opacity: fadeIn.opacity })
    }
    if (inView2) {
      animation2.start({ y: fadeIn.y, opacity: fadeIn.opacity })
    }
    if (inView3) {
      animation3.start({ y: fadeIn.y, opacity: fadeIn.opacity })
    }
    if (inView4) {
      animation4.start({ y: fadeIn.y, opacity: fadeIn.opacity })
    }

  }, [inView, inView2, inView3, inView4])

  return (
    <ContentContainer >
      {csd.map((el, index) => (
        <Section key={index}
          width={'100%'}
          margin={'10px 0'}
          zindex={'0'}
          height={'100%'}
          align_items={'center'}
        >
          <ContentBoxContainer align={'center'}
            background={el.background}
          >
            <ContentRow ref={refArray[index]}
              initial={initial}
              animate={animationArray[index]}
              width={'100%'}
              mt={'10px'}
              inverted={el.inverse}
            >
              <ContentColumn bc={'transparent'}>
                {el.title.map((ar, i) => (
                  <div key={i}>
                    <ContentItem padding={'25px'} margin={'0'} lh={'1.6rem'}>
                      <ContentSubHeading mb={'20px'} mt={'20px'} color={el.color}> {ar} </ContentSubHeading>
                      <div >
                        {el.listPosition ? listingFunction(i, el, el.listPosition, 2, initial, transition) : ''}
                      </div>
                      <ContentTextWrapper color={el.color}> {addHtml(csd[index].text[i])}</ContentTextWrapper>
                      <div >
                        {el.listPosition ? listingFunction(i, el, el.listPosition, 1, initial, transition) : ''}
                      </div>
                    </ContentItem>
                  </div>

                ))}
              </ContentColumn>
              <ContentColumn width={'100%'} >
                <ContentColumn width={'100%'} margin={'0'} padding={'25px'}>
                  {el.form ? <Form /> : <ContentImage src={el.source}
                    alt={el.alt}
                    border={'4px solid #d90'}
                    border_radius={'20px'}
                    margin={'0'}
                  />
                  }
                </ContentColumn>
              </ContentColumn>
            </ContentRow>
          </ContentBoxContainer>
        </Section>
      ))}
    </ContentContainer >
  )
}

export const listingFunction = (index, el, list_position, counter, initial, transition) => {
  if ((list_position === 1 && counter === 1) || (list_position === 2 && index === 1 && counter === 2)) {
    return (
      <ContentList initial={{ initial }}
        whileInView={{ opacity: 1 }}
        transition={{ transition }}
        amount={0.9}
        viewport={{ once: true }}
      >
        {el.list.map((li, j) => (

          <ContentListItem key={j}>
            <FontAwesomeIcon icon={faCheck} color='#d90' />
            <ContentTextWrapper color={el.color}>
              {li.item}
            </ContentTextWrapper>

          </ContentListItem>

        ))}
      </ContentList>
    )
  }
}

export default Contents
