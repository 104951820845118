import React from 'react'
import {
  FooterContainer,
  FooterLogo,
  FooterIcon,
  FooterRow,
  FooterImage,
  FooterImageRow,
  FooterTextWrapper,
} from './FooterStyles'
import { Link, useNavigate } from 'react-router-dom'
import { ContentTextWrapper, ContentImage } from '../Contents/ContentStyles'
import { faMapMarkerAlt, faPhone, faEnvelope, faLink, faMap, faEnve } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Column, Row } from '../../globalStyles'
import { FooterData } from '../../data/FooterData'

const Footer = () => {
  const navigate = useNavigate();

  const handleClick = (link) => {
    navigate(link);
  }

  return (
    <FooterContainer>
      <FooterRow>
        <FooterLogo mt={'0'} mb={'0'}>
          <FooterIcon src="./assets/Kuljetus-ja-Maansiirtoliike-K.-Timonen-Oy-logo.png"
            onClick={() => handleClick("/")}
          />
        </FooterLogo>
        <FooterImageRow width={'auto'} justify={'stretch'} align={'center'} background={'rgba(255, 255, 255, 1)'}>
          <FooterImage src={'./assets/RALA_patevyys_RGB-q33tjw5x1xozl1yrx4wz9x7km1e27xjryj5exbj3cg.png'}
            width={'clamp(8rem, 1vw, 15rem)'}
            padding={'1rem'}
            border={'none'}
          />
          <FooterImage src={'./assets/rala_laatu_RGB-q33ttddbyso2ni75kqejx13w8woau26c9fyo4rh6ls.png'}
            width={'clamp(8rem, 1vw, 15rem)'}
            padding={'1rem'}
            border={'none'}
          />
          <FooterImage src={'./assets/RALA-ymparisto_2/rala_ympäristö_RGB.png'}
            width={'clamp(8rem, 1vw, 15rem)'}
            padding={'1rem'}
            border={'none'}
          />
          <Row width={'auto'} justify={'stretch'} align={'center'} background={'rgba(255, 255, 255, 1)'}>
            <Link to={'https://www.maa-aines.com/'}>
              <ContentImage src={'./assets/maa-aines-logo.png'}
                cursor={'pointer'}
                width={'clamp(1vw, 10rem, 15rem)'}
                padding={'1rem'}
                border={'none'}
              />
            </Link>
          </Row>
        </FooterImageRow>
        <Column justify={'center'} width={'auto'}>
          <Row justify={'flex-start'} >
            <FontAwesomeIcon icon={faMapMarkerAlt} color='#d90' />
            <FooterTextWrapper href={FooterData[0].link}><strong>{FooterData[0].name}:</strong> {FooterData[0].text} </FooterTextWrapper>
          </Row>
          <Row justify={'flex-start'} >
            <FontAwesomeIcon icon={faPhone} color='#d90' />
            <FooterTextWrapper href={FooterData[1].link}><strong>{FooterData[1].name}:</strong> {FooterData[1].text} </FooterTextWrapper>
          </Row>
        </Column>
        <Column justify={'center'} width={'auto'}>
          <Row justify={'flex-start'} >
            <FontAwesomeIcon icon={faEnvelope} color='#d90' />
            <FooterTextWrapper href={FooterData[2].link}><strong>{FooterData[2].name}:</strong> {FooterData[2].text} </FooterTextWrapper>
          </Row>
          <Row justify={'flex-start'} >
            <FontAwesomeIcon icon={faLink} color='#d90' />
            <FooterTextWrapper href={FooterData[3].link}><strong>{FooterData[3].name}:</strong> {FooterData[3].text} </FooterTextWrapper>
          </Row>
        </Column>
      </FooterRow>
      <Row justify={'center'}>
        <Link to={'/tietosuojaseloste'} style={{ textDecoration: 'none', color: 'white' }}>
          <ContentTextWrapper style={{ fontSize: '0.6rem', fontWeight: '400' }}>Tietosuojaseloste</ContentTextWrapper>
        </Link>
      </Row>
    </FooterContainer>
  )
}

export default Footer
