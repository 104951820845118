import React, { useEffect } from 'react'
import { Section, Image } from '../../globalStyles'
import {
  ContentBoxContainer,
  ContentColumn,
  ContentContainer,
  ContentRow,
  ContentSubHeading,
  ContentTextWrapper,
  ContentItem,
  ContentImage,
  ContentButton,
} from './ContentStyles'
import { ContentsMaanrakennusData } from '../../data/ContentsData'
import { listingFunction } from './Contents'
import { Link, useLocation } from 'react-router-dom'
import { addHtml } from '../Functions'

const ContentsMaanrakennus = () => {

  const location = useLocation();
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [location]);

  return (
    <ContentContainer >
      {ContentsMaanrakennusData.map((el, index) => (
        <Section key={index}
          width={'100%'}
          margin={'10px 0'}
          zindex={'0'}
          height={'100%'}
          align_items={'center'}
        >
          <ContentBoxContainer align={'center'}
            background={'#eee'}
          >
            <ContentRow
              width={'100%'}
              mt={'10px'}
              inverted={el.inverse}
            >
              {el.source ?
                <>
                  <ContentColumn>
                    {
                      el.title.map((ar, i) => (
                        <ContentItem key={i} lh={'1.6rem'}>
                          <ContentSubHeading mb={'20px'} mt={'20px'}>{ar}</ContentSubHeading>
                          <div >
                            {el.listPosition ? listingFunction(i, el, el.listPosition, 1, "", "") : ''}
                          </div>
                          <ContentTextWrapper>{addHtml(ContentsMaanrakennusData[index].text[i])}</ContentTextWrapper>
                        </ContentItem>
                      ))
                    }
                    <ContentItem align_self={'auto'}>
                      {el.button ?
                        <>
                          <ContentTextWrapper>{addHtml("Maa-aines.com- verkkokauppaan: <br />")}</ContentTextWrapper>
                          <Link to={el.link}>
                            <ContentImage src={'./assets/maa-aines-logo.png'}
                              cursor={'pointer'}
                              width={'clamp(1vw, 10rem, 15rem)'}
                              padding={'1rem'}
                              border={'none'}
                            />
                          </Link>
                        </>
                        : ""}
                    </ContentItem>
                  </ContentColumn>

                  <ContentColumn>
                    <ContentItem>
                      <ContentImage src={el.source} alt={el.alt} />
                    </ContentItem>
                  </ContentColumn>
                </>
                :
                <>
                  <ContentColumn>
                    <ContentItem margin={'25px'} lh={'1.6rem'}>
                      <ContentSubHeading mb={'20px'} mt={'20px'}>{el.title}</ContentSubHeading>
                      <ContentTextWrapper>{addHtml(el.text)}</ContentTextWrapper>
                    </ContentItem>
                    <ContentItem align_self={'auto'}>
                      {el.button ?
                        <Link to={el.link}>
                          <ContentButton margin={'1rem 0'}>{el.button}</ContentButton>
                        </Link>
                        : ""}
                    </ContentItem>
                  </ContentColumn>
                </>
              }
            </ContentRow>
          </ContentBoxContainer>
        </Section>
      ))}
    </ContentContainer>
  )
}

export default ContentsMaanrakennus
