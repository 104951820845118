import styled from "styled-components";
import { Row } from "../../globalStyles";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/inject-style'

export const FormContainer = styled.form`
    display:flex;
    margin-top: 5px;
    flex-direction: column;
    padding: 10px;
    width: 100%;
`;

export const FormRow = styled(Row)`
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: orange;
    margin: 5px;
    margin-top: 5px;
`;

export const FormInput = styled.input`
    margin: 2px;
    padding: 8px;
    width: auto;
    height: 100%;
    background-color: #eee;
    border: #ccc 1px solid;
    outline: none;

    &:focus {
        transform: scale(101%);
        border: #d90 2px solid;
    }
`;

export const TextArea = styled.textarea`
    margin: 2px;
    padding: 8px;
    width: auto;
    height: 80px;
    background-color: #eee;
    border: #ccc 1px solid;
    outline: none;
    resize: none;

    &:focus {
        transform: scale(101%);
        border: #d90 2px solid;
    }
`;

export const ToastContainerBox = styled(ToastContainer)`
    width: 300px;
    height: auto;
    background-color: #222;
`;
