import React from 'react'
import {
  ContentBoxContainer,
} from '../Contents/ContentStyles'
import { HThree, Section } from '../../globalStyles'
import { FeatureBoxData } from '../../data/FeatureBoxData'
import { useNavigate } from 'react-router-dom'
import { FeatureBoxColumn, FeatureBoxRow, FeatureBoxTextWrapper, FeatureGridHThree } from './FeatureBoxStyles'

const FeatureBox = () => {

  const navigate = useNavigate();

  const handleClick = (link) => {
    navigate(link, { preventScrollReset: false })
  }

  return (
    <Section justify={'center'}
      align_items={'center'}
      width={'100%'}
      max_width={'100%'}
      mb={'3px'}
    >
      <ContentBoxContainer
        mt={''}
        height={'auto'}
        hover={''}
      >
        <FeatureBoxRow >
          {FeatureBoxData.map((el, index) => (
            <FeatureBoxColumn key={index}
              bc={el.color}
              hover_scale={true}
              onClick={() => handleClick(el.link)}
              cursor={'pointer'}>
              <FeatureGridHThree padding={'0 0 10px 0'}
                align_self={'flex-start'}
                color={'#fff'}
              >
                {el.title}
              </FeatureGridHThree>
              <FeatureBoxTextWrapper color={'#fff'}>
                {el.text}
              </FeatureBoxTextWrapper>
            </FeatureBoxColumn>
          ))}
        </FeatureBoxRow>
      </ContentBoxContainer>
    </Section>

  )
}

export default FeatureBox
