import React, { useEffect, useState } from 'react'
import Slider from 'react-slick'
import { CarouselArrowLeft, CarouselArrowRight, CarouselFocuser, CarouselImage, SliderContainer } from './CarouselStyles';
import { CarouselImages } from '../../data/CarouselData';
import { Row } from '../../globalStyles';

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

/**********************************************************|
 ***************|===========================|**************|
 ***************|~Kuvakaruselli-komponentti~|**************|
 ***************|===========================|**************|
 *
 * Kuvakaruselli-komponentti. 
 * settings- olio määrittää kuvakarusellin asetukset
 * 
 */


const Carousel = ({ carouselProps, imageIndex, handleImageClick, setImageIndex, setImagesLarge }) => {
  const [images, setImages] = useState([]);
  const slider = React.useRef(null);

  useEffect(() => {
    let tempImages = []
    CarouselImages.forEach((img, i) => {
      tempImages[i] = img.source
    })
    setImages(CarouselImages);
    setImagesLarge(tempImages);
  }, []);

  const settings = {
    dots: true,
    speed: 500,
    infinite: true,
    slidesToShow: 1,
    slidesPerRow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    fade: true,

    // autoplay: true,
    // autoplaySpeed: 2000,
    // focusOnSelect: true,
    arrows: false,
  };

  const updateIndex = (carouselArrow) => {

    if (carouselArrow == "previous") {
      setImageIndex(imageIndex - 1)
    } else if (carouselArrow == "previous" && imageIndex === 0) {
      setImageIndex(images.length)
    } else if (carouselArrow == "next") {
      setImageIndex(imageIndex + 1)
    } else if (carouselArrow == "next" && imageIndex == images.length - 1) {
      setImageIndex(0)
    }

    if (imageIndex < 0) {
      setImageIndex(images.length - 1)
    } else if (imageIndex >= images.length) {
      setImageIndex(0)
    }
  }

  const handleAfterChange = (index) => {
    setImageIndex(index)
  }

  return (
    <Row mb={'50px'}>
      <CarouselArrowLeft onClick={() => {
        slider?.current?.slickPrev();
        updateIndex("previous")
      }} />
      <SliderContainer>
        <Slider ref={slider} {...settings} afterChange={handleAfterChange}>
          {images.map((img, index) => (
            <CarouselFocuser onClick={handleImageClick}
              key={index}
              height={carouselProps.height}
            >
              <CarouselImage src={img.source}
                height={carouselProps.height}
                border_radius={carouselProps.border_radius}
                filter={carouselProps.filter}
                min_height={carouselProps.minHeight}
                object_fit={carouselProps.object_fit}
              />
            </CarouselFocuser>
          ))}
        </Slider>
      </SliderContainer>
      <CarouselArrowRight onClick={() => {
        slider?.current?.slickNext()
        updateIndex("next")
      }} />
    </Row>
  )
}

export default Carousel
