import React from 'react'
import Hero from '../components/Hero/Hero'
import ContentsKalusto from '../components/Contents/ContentsKalusto'

/**
 *    +===========+
 *    | ~Kalusto~ |
 *    +===========+
 * 
 *    Kaluston esittelysivu. Sivulla tavallisten Navbar-, Hero-, ja 
 *    Content-komponenttien lisäksi Carousel-kuvakaruselli.  
 */

const Kalusto = () => {

  return (
    <>
      <Hero heroHeight={'100vh'} heroDataParameter={"kalusto"} mb={'0'} />
      <ContentsKalusto />
    </>
  )
}

export default Kalusto
