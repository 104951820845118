import styled, { keyframes } from "styled-components";
import { Button, Arrow, PageContainer, Row, TextWrapper } from "../../globalStyles";
import { motion } from "framer-motion";


export const ContentContainer = styled(PageContainer)`

    padding: 20px 0;
    > { box-sizing: content-box; }

    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: -5;
    margin-bottom: ${({ mb }) => (mb ? mb : '0')};
    background: transparent;
`;

export const ContentBackgroundImage = styled.img`
    width: ${({ width }) => (width ? width : '100%')};
    height: ${({ height }) => (height ? height : 'auto')};
    top: ${({ top }) => (top ? top : '0')};
    object-fit: cover;
    position: absolute;
    z-index: 1;
    opacity: 0.4;
`;

export const ContentBoxContainer = styled.div`

    border-radius: ${({ border_radius }) => (border_radius ? border_radius : '40px')};
    display: flex;
    margin-top: ${({ mt }) => (mt ? mt : '0')};
    margin-bottom: ${({ mb }) => (mb ? mb : '10px')};
    padding: ${({ padding }) => (padding ? padding : '')};
    width: ${({ width }) => (width ? width : '75vw')};
    justify-content: center;
    align-items: ${({ align }) => (align ? align : '')};
    height: ${({ height }) => (height ? height : 'auto')};
    background: ${({ background }) => (background ? background : '')};
    flex-direction: column;

    @media screen and (max-width: 1280px) {
        padding: 0;
        width: 100vw;
        max-width: 100vw;
    }
`;


export const ContentRow = styled(motion.div)`

    display: flex;
    flex: ${({ flex }) => (flex ? flex : '1')};
    flex-direction: ${({ inverted }) => (inverted ? 'row-reverse' : 'row')};
    width: ${({ width }) => (width ? width : '100%')};
    margin-top: ${({ mt }) => (mt ? mt : '0')};
    align-items: center; 
    justify-content: ${({ justify_content }) => (justify_content ? justify_content : 'center')};
    flex-wrap: nowrap;
    margin-bottom: 20px;
    flex: 1;
    max-width: 95vw;
    background:transparent;
    padding: ${({ padding }) => (padding ? padding : '0 30px')};

    @media screen and (max-width:1280px){
        width:100%;
        padding: 0 15px;
    }

    @media screen and (max-width: 1024px) {
        display:flex;
        padding: 0 5px;
        flex-direction: column;
        max-width: 100vw;
        height: 100%;
    }
`;

export const ContentYhteystiedotRow = styled(ContentRow)`
    @media screen and (max-width: 1024px) {
        flex-direction: row;
    }

    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
`;



export const ContentColumn = styled.div`
    border-radius: ${({ border_radius }) => (border_radius ? border_radius : '20px')};
    flex: ${({ flex }) => (flex ? flex : '1')};
    display: flex;
    word-break: break-word;
    overflow: hidden;
    flex-direction: column;
    margin-bottom: 15px;
    justify-content: center;
    margin: 3px 0;
    padding: ${({ padding }) => (padding ? padding : '0')};
    height:${({ height }) => (height ? height : 'auto')};
    width:${({ width }) => (width ? width : 'auto')};

    cursor: ${({ cursor }) => (cursor ? cursor : 'initial')};

    background-color: ${({ bc }) => (bc ? bc : '')};

    @media screen and (max-width: 1024px){
        width: 100%;
        margin: 0;
    }


    &:hover {
        transform: ${({ hover_scale }) => (hover_scale ? 'scale(105%)' : 'null')};
    }

`;

export const ContentItem = styled.div`
    flex: ${({ flex }) => (flex ? flex : '1')};
    width: ${({ width }) => (width ? width : '')};
    height: ${({ height }) => (height ? height : 'auto')};
    min-height: ${({ min_height }) => (min_height ? min_height : '')};
    justify-content: space-between;
    color: ${({ color }) => (color ? color : '#222')};
    font-weight: 500;
    line-height: ${({ lh }) => (lh ? lh : '')};
    align-self: ${({ align_self }) => (align_self ? align_self : 'flex-start')};
    margin:${({ margin }) => (margin ? margin : '0')};
    padding: ${({ padding }) => (padding ? padding : '25px')};
    word-wrap: ${({ word_wrap }) => (word_wrap ? word_wrap : 'break-word')} ;
    overflow: hidden;
    overflow-wrap: ${({ overflow_wrap }) => (overflow_wrap ? overflow_wrap : 'break-word')};
    font-size: clamp(0.8rem, 2.5vh, 1.2rem);
    text-align: ${({ text_align }) => (text_align ? text_align : 'start')};

    @media screen and (max-width: 1024px) {
        width:100%;
    }
`;

export const ContentButton = styled(Button)`
    margin-left: ${({ ml }) => (ml ? ml : '0')};
    min-width: fit-content;
    margin: ${({ margin }) => (margin ? margin : '2rem 0 0 0')};
    font-size: 0.8rem; 
    font-weight: 600; 
`;

export const ContentList = styled(motion.ul)`
    text-decoration: none;
    padding: 0 1rem;
    margin: 15px 0;
    text-align: start;
    list-style-type: none;
`;

export const ContentListItem = styled(motion.li)`
    font-size: ${({ font_size }) => (font_size ? font_size : 'clamp(0.9rem, 2.5vh, 1.1rem)')};
    padding: 2px 0 ;
    color: ${({ color }) => (color ? color : 'inherit')};
    white-space: pre-wrap;
    line-height: ${({ lh }) => (lh ? lh : '1.8rem')};
    list-style: ${({ listStyle }) => (listStyle ? listStyle : 'unset')};
`;

export const ContentTextWrapper = styled.p`
    display: ${({ display }) => (display ? display : 'inline')};
    margin: ${({ margin }) => (margin ? margin : '10px 0')};
    padding: 0;
    font-size: ${({ font_size }) => (font_size ? font_size : 'clamp(0.9rem, 2.5vh, 1.1rem)')}; 
    font-weight: ${({ font_weight }) => (font_weight ? font_weight : '500')};
    color: ${({ color }) => (color ? color : 'inherit')};
    margin-bottom: ${({ mb }) => (mb ? mb : '0')};
    line-height: ${({ lh }) => (lh ? lh : '1.8rem')};
    overflow: visible;
`;

export const ContentEmployeeCardTextWrapper = styled(ContentTextWrapper)`
    text-align: center;
`

export const ContentSubHeading = styled.h2`
    font-size: clamp(1.4rem, 1.8rem, 2.4rem);
    text-align: ${({ textAlign }) => (textAlign ? textAlign : 'center')};
    padding: ${({ padding }) => (padding ? padding : '0')};
    color: ${({ color }) => (color ? color : 'inherit')};
    margin-top: ${({ mt }) => (mt ? mt : '0')};
    margin-bottom: ${({ mb }) => (mb ? mb : '0')};
    line-height: ${({ lh }) => (lh ? lh : '2.2rem')};
`;

export const ContentImage = styled.img`
    padding: ${({ padding }) => (padding ? padding : '0')};
    margin: ${({ margin }) => (margin ? margin : '0')};
    border: ${({ border }) => (border ? border : '4px solid #d90')};
    border-radius: ${({ border_radius }) => (border_radius ? border_radius : '20px')};
    width: ${({ width }) => (width ? width : 'auto')};
    cursor: ${({ cursor }) => (cursor ? cursor : '')};
    max-width: 100%;
    height: auto;

    @media screen and (max-width: 1024px) {
        min-width: 8rem;
        height: auto;
    }

    @media screen and (max-width: 768px) {
        min-width: 150px;
    }

`;

export const ContentIframe = styled.iframe`
    border:0;
    width:100%;
    height:400px; 
    padding: 25px 25px 0 0;

    @media screen and (max-width: 768px){
        padding-left: 25px;
    }
`;
