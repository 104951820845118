export const ContentsSectionData = [
  {
    title: ['Maanrakennus- ja maansiirtopalvelut Lahti & Päijät-Häme'],
    subTitle: 'Kaikenkokoiset urakat luotettavasti aina suunnittelusta toteutukseen!',
    text: ['<strong>Kuljetus- ja Maansiirtoliike K. Timonen Oy</strong> on pitkän linjan maanrakennusalan ammattilainen, joka tarjoaa maanrakennus-ja maansiirtotyöt, rakennuskohteiden massanvaihdot ja erilaiset kaivuutyöt suuriin ja pieniin kohteisiin. Urakoimme niin uudis-kuin saneerauskohteissakin. Yrityksemme kotikaupunki on Lahti, mutta palvelemme laajasti myös muualla Päijät-Hämeen seudulla mukaan lukein esimerkiksi Heinola, Mäntsälä, Hollola ja Nastola. Työt teemme yli 20 huippuammattilaisen voimin, ja porukastamme löytyy niin iän tuomaa kokemusta kuin nuoruuden intoakin. Oman väen lisäksi meillä on myös laaja ja luotettava alihankkijoiden verkosto, jolla takaamme laadukkaan palvelun jokaiselle asiakkaalle. Kun haluat projektisi sujuvan sovitusti ja ajallaan, käänny meidän puoleemme.'],
    listPosition: 0,
    list: [{ item: "" }],
    button: '', //'Ota yhteys ja kysy tarjous projektistasi',
    link: '', //'/yhteystiedot',
    source: "./assets/BRK_6276.jpg",
    alt: "Kaivinkone",
    form: 0,
    background: '#eee',
    color: '#222',
    inverse: false,
  },
  {
    title: ['Monipuoliset maanrakennustyöt jo vuodesta 1992'],
    subTitle: '',
    text: ['Olemme tunnettu ja luotettu paikallisyritys, jota on luotsannut sama omistaja aina perustamisvuodestamme 1992 lähtien. Vuosien aikana niin palvelumme kuin henkilökuntamme määräkin ovat laajentuneet, ja tänä päivänä olemme yksi Päijät-Hämeen johtavista maanrakennusyrityksistä. Palveluihimme kuuluvat:'],
    listPosition: 1,
    list: [
      { item: '  Kaikki maanrakennus- ja kaivuutyöt' },
      { item: '  Maansiirto ja kuljetukset' },
      { item: '  Maa- ja kiviainekset sekä mullat toimitettuina ' },
      { item: '  Salaojitus' },
      { item: '  Sadevesijärjestelmät' },
      { item: '  Piha-alueiden viemäröinnit' },
      { item: '  Routaeristystyöt' },
      { item: '  Lumen auraus ja kuljetus' },
    ],
    button: '', //'Lue lisää palveluistamme!',
    link: '', //'/maanrakennustyot',
    source: "./assets/BRK_5794.jpg",
    alt: "Punainen kuorma-auto",
    form: 0,
    background: '#eee',
    color: '#222',
    inverse: true,
  },
  {
    title: ['Asiakkaina niin yrityksiä, kuntia kuin koti-talouksiakin ',
      'Esimerkkejä työkohteistamme',
      'Kotitalousvähennys yksityisille asiakkaille '
    ],
    subTitle: '',
    text: ['Toimintavuosiemme aikana olemme palvelleet lukuisia asiakkaita; työhömme ovat olleet tyytyväisiä mm. Lahden kisakylä, Lahden kaupunki ja useita asunto-osakeyhtiöitä koko Päijät-Hämeen seudulla. Meihin luottavat niin yksityiset asiakkaat ja yritykset, kuin kaupungit ja kunnatkin. Palvelemmekin kattavasti kaikkia maanrakennus- maansiirto- tai kuljetuspalveluita tarvitsevia asiakkaita kokoon katsomatta. Kalustoa ja henkilökuntaa löytyy pieniin & suuriin urakoihin – ota siis yhteyttä ja kerro, miten voisimme olla avuksi.',
      'Referenssikohteita on toimintavuosiemme varrella kertynyt niin lukuisa määrä, että täydellistä listausta on mahdotonta sisällyttää verkkosivuillemme. Kysyttäessä kerromme mielellämme lisää siitä, minkälaisia urakoita olemme toteuttaneet!',
      'Oletko suunnitellut kotipihan tai mökkipihan muokkaamista uuteen uskoon? Kun teetät kunnossapito- tai perusparannustöitä kotona kotona tai vapaa-ajan asunnolla, olet oikeutettu kotitalousvähennykseen. Vähennys myönnetään vain työn osuudesta, ei matkakuluista tai tarvikkeista. Lue lisää kotitalousvähennyksestä Verohallinnon sivuilta!'
    ],
    listPosition: 2,
    list: [
      { item: '  Lahden kaupunki (katurakentaminen)' },
      { item: '  Lahden kisakylä (maa- ja pohjarakennustyöt) ' },
      { item: '  Asunto-osakeyhtiöiden, koulujen, päiväkotien ja liikeyritysten maanrakennus- ja pihatöitä ' },
      { item: '  Pilaantuneiden maa-alueiden puhdistustöitä ' },
      { item: '  Rakennusten kosteus- ja vesieristeiden korjausurakoita taloyhtiöille ja kouluille ' },
      { item: '  Piha-alueiden massanvaihtoja ja viemäreiden asennustöitä' },
      { item: '  Rudus Oy:n kiviaineskuljetukset vuodesta 1990 lähtien ' },
    ],
    button: '',
    link: '',
    source: "./assets/BRK_9124.2.jpg",
    alt: "Kaivinkone",
    form: 0,
    background: '#eee',
    color: '#222',
    inverse: false,
  },
  {
    title: ['Tilaa meidät kartoituskäynnille tai pyydä tarjousta'],
    subTitle: '',
    text: ['Tiedustele kartoituskäynnin mahdollisuutta tai mikäli projektisi on selkeä, pyydä tarjous työstä saman tien. Tarjouspyyntö ei sido sinua vielä mihinkään. Palaamme asiaan mahdollisimman pian!'],
    listPosition: 0,
    list: [{ item: "" }],
    button: '',
    link: '',
    source: "./assets/BRK_6014.jpg",
    alt: "",
    form: 1,
    background: '#eee',
    color: '#222',
    inverse: false,
  },
];

export const ContentsMaanrakennusData = [
  {
    title: ['Maansiirto- ja maanrakennustyöt Lahti–Päijät-Häme -seudulla'],
    subTitle: '',
    text: ['Luota kokeneen ammattiyrityksen osaamiseen! <strong>Kuljetus- ja Maansiirtoliike K. Timonen Oy</strong> on luonteva valinta toteuttamaan kaikenlaiset maansiirto- ja maanrakennustyöt. Teemme pienet ja suuret urakat varmalla ammattitaidolla, luotettavasti ja aikataulussa. Asiakkaisiimme lukeutuvat niin yksityisasiakkaat kuin yrityksetkin julkista sektoria unohtamatta. Kotipihat, taloyhtiöt, liikepaikat, kunnat, kaupungit, koulut, päiväkodit, palvelutalot – meillä on kokemusta kaikenlaisissa työympäristöissä toimimisesta. Suuret urakat ovat erikoisalaamme, jotka suunnittelemme ja toteutamme yli 25 vuoden kokemuksella. Tilaa siis meidät urakoimaan maanrakennustyöt; Lahti ympäryskuntineen on pääasiallista palvelualuettamme, mutta palvelemme joustavasti koko Päijät-Hämeen alueella.'],
    listPosition: 0,
    list: [{ item: "" }],
    button: '', //'Ota yhteys ja kysy tarjous projektistasi',
    link: '', //'/yhteystiedot',
    source: "./assets/BRK_6276.jpg",
    alt: "",
    form: 0,
    background: '#eee',
    color: '#222',
    inverse: false,
  },
  {
    title: ['Palveluihimme kuuluvat seuraavat maanrakennustyöt:'],
    subTitle: '',
    text: ['Teemme niin uudisrakennus- kuin saneeraustöitäkin. Urakan sisällöstä sovitaan aina yksilöllisesti tilaajan kanssa. Urakan hintaan vaikuttavat muun muassa työmaan arvioitu kesto, urakan laajuus ja sijainti. Laadimme kohteesta selkeän urakkatarjouksen asiakkaan aikataulun & muut tarpeet huomioon ottaen.'],
    listPosition: 1,
    list: [{ item: "  rakennusten pohja- ja perustustyöt" },
    { item: "  kunnallistekniikka" },
    { item: "  tienrakennus" },
    { item: "  salaojitus" },
    { item: "  pihojen viemäröinti ja sadevesijärjestelmät" },
    { item: "  maa-ainekset ja maa-aineskuljetukset" },
    { item: "  maansiirto" },
    { item: "  lumenauraus ja pihojen/teiden hiekoitus" },
    { item: "  routaeristystyöt" },
    ],
    button: '',
    link: '',
    source: "./assets/BRK_9071.2.jpg",
    alt: "Kaivinkoneen kauha",
    form: 0,
    background: '#eee',
    color: '#222',
    inverse: true,
  },
  {
    title: ['Toteutamme ongelmitta myös laajemmat työmaat', 'Maa-ainekset netistä – tilaa helposti kumppaniyritykseltämme!'],
    subTitle: '',
    text: ['Viime vuosina olemme palvelleet erityisesti isompia rakennusliikkeitä, joiden urakat olemme vieneet maaliin sekä omien, että yhteistyökumppaniemme resurssien avulla. Meillä on erinomainen henkilökunta sekä monipuolinen kalusto omastakin takaa, mutta niiden lisäksi olemme katsoneet järkeväksi panostaa hyvään & toimivaan alihankkijaverkostoon. Tuttujen ja luotettavien alihankkijoiden avulla voimme toteuttaa laajamittaisemmatkin urakat sujuvasti ilman viivytyksiä.', 'Olitpa sitten kotipihan kohentaja tai suuren työmaan urakoitsija, voit tilata tarvitsemasi maa-ainekset helposti ja nopeasti kumppaniyrityksemme Maa-aines.comin verkkokaupasta. Valikoimaan kuuluvat sepeli, hiekka ja sora, nurmikko- ja puutarhamulta, kuorikatteet ja luonnonkivet. Maa-aines.com välittää tilauksesi tiedot meille, ja kuljetamme tilaamasi maa-ainekset haluamaasi aikaan, haluamallesi paikalle. Lisätietoja löydät verkkokaupan sivuilta. Tee tilaus milloin vain – maa-ainesten nettikauppa on auki 24h vuorokaudessa!'],
    listPosition: 0,
    list: [{ item: "" },],
    button: 'maa-aines.com',
    link: 'https://www.maa-aines.com/',
    source: "./assets/BRK_6336.jpg",
    alt: "Kaivinkoneen kauha, jossa kiviä",
    form: 0,
    background: '#eee',
    color: '#222',
    inverse: false,
  },
]

export const ContentsKalustoData = [
  {
    title: ['Maanrakennus, maansiirto ja maa-aineskuljetukset Lahdesta'],
    subTitle: '',
    text: ['<strong>Nykyaikainen, huollettu kalusto mahdollistaa monipuolisen palvelun</strong>. Ensiluokkainen palvelu ei olisi mahdollista ilman ensiluokkaista kalustoa. Vakikalustoon laskemme kuuluvaksi niin parisenkymmentä työntekijäämme kuin laajan konekantamme, josta löytyvät niin kaivurit, pyöräkuormaajat kuin vaihtolava-autotkin. Pätevien osaajiemme sekä hyvien, huollettujen koneiden avulla teemme maanrakennus- ja maansiirtotöitä rakennusliikkeille, teollisuudelle, kunnille, yhdistyksille, taloyhtiöille, liikelaitoksille ja yksityisille asiakkaille. Lisäksi hoidamme luotettavasti ja ajallaan erilaiset maa-aineskuljetukset. Lahti, Heinola, Mäntsälä, Hollola, Nastola ja koko Päijät-Häme on palvelualuettamme, joten asiakkaat voivat tilata meidät joustavasti töihin myös Lahden ulkopuolelle.'],
    listPosition: 0,
    list: [{ item: "" }],
    button: '',
    link: '',
    source: './assets/BRK_6583.jpg',
    alt: "",
    form: 0,
    background: '#eee',
    color: '#222',
    inverse: false,
  },
  {
    title: ['Työskentelemme omalla kalustolla, johon kuuluu:'],
    subTitle: '',
    text: ['Moderni, huollettu konekanta mahdollistaa tehokkaan ja turvallisen työskentelyn. Urakan eteneminen ei saa seisahtua konerikkoon, ja tästä pidämme huolen ylläpitämällä ja päivittämällä konekantaamme tarpeen mukaan.'],
    listPosition: 1,
    list: [{ item: "  Tela- ja pyöräalustaiset kaivinkoneet" },
    { item: "  Pyöräkuormaajat" },
    { item: "  Kasettiautot" },
    { item: "  Vaihtolava-autot" },
    { item: "  Lavetit" },
    { item: "  Valssijyrät" },
    ],
    button: '',
    link: '',
    source: './assets/BRK_6633.jpg',
    alt: "Kaivinkoneita rivissä",
    form: 0,
    background: '#eee',
    color: '#222',
    inverse: true,
  },
]

export const ContentsYhteystiedotData = [
  {
    title: ['Kuljetus- ja Maansiirtoliike K. Timonen Oy, Lahti'],
    subTitle: '',
    text: ['</br><strong>Näin maanrakennustöiden kuuluisi aina sujua</strong>', '<br>Kun etsit urakoitsijaa tekemään maanrakennus- tai maansiirtotyöt ja hoitamaan maa-aineskuljetukset, käänny lahtelaisen <strong>Kuljetus- ja Maansiirtoliike K. Timonen Oy</strong>:n puoleen. Sinua palvelee reipas joukko paikallisia maanrakennusalan ammattilaisia, joiden kanssa projektisi etenee sovitusti & ajallaan maaliinsa. Yli 25 vuoden aikana hankittu kokemus alalta antaa vankan pohjan kaikenlaisiin urakoihin – meille mikään työ ei ole liian pieni tai suuri. Tutustu palveluihimme sekä kalustoomme ja ota yhteyttä! Toimimme koko Päijät-Hämeen seudulla tarjoten asiakkaillemme luotettavaa, kotimaista ammattitaitoa kaikkiin maanrakennustöihin.'],
    listPosition: 0,
    list: [{ item: "" }],
    button: '',
    link: '',
    source: './assets/BRK_6014.jpg',
    alt: "",
    form: 0,
    background: '#eee',
    color: '#222',
  },
  {
    title: ['Yhteystiedot'],
    subTitle: '',
    text: ['<strong>Kuljetus- ja Maansiirtoliike K. Timonen Oy</strong>', 'Vieterikatu 12 15700 LAHTI', '<a href="tel:0405400334">Puh. 040 540 0334</a>', '<a href="mailto:info@maanrakennustimonen.fi">info@maanrakennustimonen.fi</a>'],
    listPosition: 0,
    list: [{ item: "" }],
    button: '',
    link: '',
    source: '',
    alt: "",
    form: 0,
    background: '#eee',
    color: '#222',
  },
  {
    title: [''],
    subTitle: 'Verkkolaskutus',
    text: ['<strong>Operaattori</strong>: Apix Messaging Oy (003723327487)', '<strong>Verkkolaskuosoite</strong>: 003708837673', '<strong>OVT-tunnus</strong>: 003708837673'],
    listPosition: 0,
    list: [{ item: "" }],
    button: '',
    link: '',
    source: '',
    alt: "",
    form: 0,
    background: '#eee',
    color: '#222',
  },
  {
    title: [''],
    subTitle: 'Paperilaskut',
    text: ['Kuljetus- ja Maansiirtoliike K. Timonen Oy', '(Apix skannauspalvelu)', 'PL 16112', '00021 LASKUTUS'],
    listPosition: 0,
    list: [{ item: "" }],
    button: '',
    link: '',
    source: '',
    alt: "",
    form: 0,
    background: '#eee',
    color: '#222',
  },
  {
    title: ['Tilaa meidät kartoituskäynnille tai pyydä tarjousta'],
    subTitle: '',
    text: ['Tiedustele kartoituskäynnin mahdollisuutta tai mikäli projektisi on selkeä, pyydä tarjous työstä saman tien. Tarjouspyyntö ei sido sinua vielä mihinkään. Palaamme asiaan mahdollisimman pian!'],
    listPosition: 0,
    list: [{ item: "" }],
    button: '',
    link: '',
    source: "./assets/BRK_6014.jpg",
    alt: "",
    form: 1,
    background: '#eee',
    color: '#222',
    inverse: false,
  },
]

export const myynti = [
  {
    name: 'Kimmo Timonen',
    title: 'Toimitusjohtaja',
    phone: '040 540 0334',
    e_mail: 'kimmo.timonen@maanrakennustimonen.fi',
  },
  {
    name: 'Jaakko Virolainen',
    title: 'Työnjohtaja, RKM',
    phone: '044 786 3920',
    e_mail: 'jaakko.virolainen@maanrakennustimonen.fi',
  },
  {
    name: 'Arto Laaksonen',
    title: 'Työnjohtaja',
    phone: '0400 499 189',
    e_mail: 'arto.laaksonen@maanrakennustimonen.fi',
  },
  {
    name: 'Johanna Timonen',
    title: 'Työnjohtaja, RKM',
    phone: '044 786 3901',
    e_mail: 'johanna.timonen@maanrakennustimonen.fi',
  },
  {
    name: 'Roosa Timonen',
    title: 'Kuljetus',
    phone: '044 786 3912',
    e_mail: 'roosa.timonen@maanrakennustimonen.fi',
  },
]
/*
    Tyhjä pohja uusien tietueiden luomiseen. 
{
        title:[''],
        subTitle:'',
        text: [''],
        listPosition: 0,
        list: [{item:""}],
        button:'',
        link:'',
        source:'',
        form: 0,
    }
    */
