export const HeroData = [
  {
    title: "",
    subTitle: "Maanrakennustyöt vankalta pohjalta",
    text: "Pätevä henkilöstö, järeä kalusto ja mutkaton palvelu tekevät meistä parhaan kumppanin kaikkiin alan töihin",
    button: "Soita meille",
    image: "./assets/BRK_9079.3.jpg",
  },
  {
    title: "",
    subTitle: "Maanrakennustyöt",
    text: "",
    button: "",
    image: "./assets/BRK_6638.jpg",
  },
  {
    title: "",
    subTitle: "Kalusto",
    text: "",
    button: "",
    image: "./assets/BRK_6636.jpg",
  },
  {
    title: "",
    subTitle: "Yhteystiedot",
    text: "",
    button: "",
    image: "./assets/BRK_9079.3.jpg",
  },
]
