import React, { useEffect, useState } from 'react'
import { Section } from '../../globalStyles'
import {
  ContentBoxContainer,
  ContentColumn,
  ContentContainer,
  ContentRow,
  ContentSubHeading,
  ContentTextWrapper,
  ContentItem,
  ContentImage,
  ContentButton,
} from './ContentStyles'
import ImageModal from '../ImageModal/ImageModal'
import { ContentsKalustoData } from '../../data/ContentsData'
import { listingFunction } from './Contents'
import { Link, useLocation } from 'react-router-dom'
import Carousel from '../Carousel/Carousel'
import { addHtml } from '../Functions'

const ContentsKalusto = () => {
  const [imageClicked, setImageClicked] = useState(false);
  const [images, setImages] = useState([]);
  const [imageIndex, setImageIndex] = useState(0);

  const location = useLocation();
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [location]);

  const handleExitClick = () => {
    setImageClicked(false);
  }

  const carouselProps = {
    position: 'static',
    border_radius: '5vh',
    height: '50vh',
  }

  return (
    <ContentContainer >
      {ContentsKalustoData.map((el, index) => (
        <Section key={index}
          width={'100%'}
          margin={'10px 0'}
          zindex={'0'}
          height={'100%'}
          align_items={'center'}
        >
          <ContentBoxContainer align={'center'}
            background={'#eee'}
          >
            <ContentRow
              width={'100%'}
              mt={'10px'}
              inverted={el.inverse}
            >
              {el.source ?
                <>
                  <ContentColumn>
                    {
                      el.title.map((ar, i) => (
                        <ContentItem key={i} lh={'1.6rem'}>
                          <ContentSubHeading mb={'20px'} mt={'20px'}>{ar}</ContentSubHeading>
                          <div >
                            {el.listPosition ? listingFunction(i, el, el.listPosition, 1, "", "") : ''}
                          </div>
                          <ContentTextWrapper>{addHtml(ContentsKalustoData[index].text[i])}</ContentTextWrapper>
                          {/* <div >
                                                    { el.listPosition ? listingFunction(i, el, el.listPosition, 1, {opacity: 1, y: 0}, { opacity: 1, y: 0}) : ''}
                                                </div> */}

                        </ContentItem>
                      ))
                    }
                    <ContentItem align_self={'auto'}>
                      {el.button ? <Link to={el.link}>
                        <ContentButton margin={'1rem 0'}>{el.button}</ContentButton>
                      </Link> : ""}
                    </ContentItem>
                  </ContentColumn>

                  <ContentColumn>
                    <ContentItem>
                      <ContentImage src={el.source} alt={el.alt} />
                    </ContentItem>
                  </ContentColumn>
                </>
                :
                <>
                  <ContentColumn>
                    <ContentItem margin={'25px'} lh={'1.6rem'}>
                      <ContentSubHeading mb={'20px'} mt={'20px'}>{el.title}</ContentSubHeading>
                      <ContentTextWrapper>{addHtml(el.text)}</ContentTextWrapper>
                    </ContentItem>
                  </ContentColumn>
                </>
              }
            </ContentRow>
          </ContentBoxContainer>
        </Section>
      ))}
      <Section width={'100%'}
        margin={'10px 0'}
        zindex={'0'}
        height={'100%'}
        align_items={'center'}
      >
        <ContentBoxContainer align={'center'}
          background={'#eee'}
          mb={'25px'}
        >
          <ContentSubHeading mb={'50px'} mt={'25px'}>Kuvagalleria:</ContentSubHeading>
          <Carousel
            carouselProps={carouselProps}
            handleImageClick={() => setImageClicked(true)}
            imageIndex={imageIndex}
            setImageIndex={setImageIndex}
            setImagesLarge={setImages}
          />
        </ContentBoxContainer>
        <ImageModal is_open={imageClicked}
          onClose={handleExitClick}
          imageIndex={imageIndex}
          images={images}
        />
      </Section>
    </ContentContainer>
  )

}

export default ContentsKalusto
